<!-- 现烤订单制作 -->
<template>
    <div class="wrap">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />

      <!-- 新增和单独编辑对话框 -->
      <c-dialog title="选择现烤师" :width="700" :showDialog.sync="dialogFormVisible">
        <template #content>
          <div style="display: flex;">
            <div class="workerBox">
              <div @click="workerClick(index)" v-for="(item, index) in workerList" :class="workerIndex == index ? 'activeLi' : ''" :key="index" class="li">{{ item.workerName }}</div>
            </div>
            <div style="flex: 1;">
              <el-form :model="form" :rules="rules" ref="ruleForm" >
                <el-form-item label="生产数量" :label-width="formLabelWidth" prop="freshlyBakedFinishQty">
                  <el-input maxlength="20" class="brandInput" v-model.number="form.freshlyBakedFinishQty" autocomplete="off"
                    placeholder="请输入生产数量"></el-input>
                </el-form-item>
                <el-form-item label="报损数量" :label-width="formLabelWidth" prop="freshlyBakedScrapQty">
                  <el-input maxlength="40" class="brandInput" v-model.number="form.freshlyBakedScrapQty" autocomplete="off"
                    placeholder="请输入报损数量"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </template>
        <template #buttonList>
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="addBrand">确 定</el-button>
        </template>
      </c-dialog>
    </div>
    
  </template>
  <script>
  import CDialog from "@/components/dialogTemplate/dialog/index.vue";
  import TablePage from "@/components/tablePage";
  import Dialog from '@/components/Dialog'
  import { listBrand } from "@/api/freshlyBaked/base/teacher.js";
  import { listOrder, freshlyProduceFinish, freshlyMoveToShop } from '@/api/freshlyBaked/bill/orderManage' //现烤报损
  export default {
    components: { TablePage, Dialog, CDialog },
    dicts: ["bill_status",],
    data() {
      return {
        formLabelWidth: "120px", //表单宽度
        workerList: [],
        workerIndex: 0,
        rules: {
          freshlyBakedFinishQty: [
            {
              required: true,
              message: "生产数量不能为空",
              trigger: ["blur", "change"],
            },
            {
              pattern: /^(?:1000|[0-9]\d?)$/,
              message: "必须输入0-1000的整数",
              trigger: ["blur", "change"],
            },
          ],
          freshlyBakedScrapQty: [
            {
              required: true,
              message: "报损数量不能为空",
              trigger: ["blur", "change"],
            },
            {
              pattern: /^(?:1000|[0-9]\d?)$/,
              message: "必须输入0-1000的整数",
              trigger: ["blur", "change"],
            },
          ],
          workerName: [
            {
              required: true,
              message: "现烤师名称不能为空",
              trigger: ["blur", "change"],
            },
            { pattern: /^.{1,40}$/, message: "请输入1 ~ 40个字符" },
          ],
        },
        form: {
          freshlyBakedFinishQty: "",
          freshlyBakedScrapQty: "",
          remark: "",
        },
        dialogFormVisible: false,
        dialogOptions: {
          curType: '',
          title: '选择现烤间',
          width: 1250,
          show: false,
          type: 'TreeAndTable'
        },
        options: {
          mutiSelect: true, // 多选
          rideoCheck: true,
          rideoStatus: '1',
          rideoDataList: [
            {
              label: '待生产',
              value: '1',
            },
            {
              label: '生产完成',
              value: '2',
            },
            {
              label: '已调出',
              value: '3',
            },
          ],
          loading: true,
          pagination: {
            total: 0,
            page: 1,
            size: 15,
          },
          exportOption: {
            show: true,
            exportApi: 'freshlyOrderExport',
            exportName: '现烤订单制作列表',
            timeout: 60*5*1000
          },
          defaultBody: { freshlyBakedProduceStatus: 1 },
          getListApi: listOrder,
          body: {},
          listNo: true, // 序号
          title: "单据信息",
          check: [], // 选中数据
          rowKey: "billId",
          search: [
            {
              label: "单据日期",
              type: "daterange",
              model: "",
              filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
            },
            {
              keyIndex: 0,
              format: "daterange",
              type: "timeRange",
              defaultValue: "month",
              data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
            },
            {
              type: "filters",
              tip: "全部/订单编号/门店名称/门店编号",
              isRestore:true,
              model: "",
              filters: [
                { filter: "query", label: "全部" },
                { filter: "billNos", label: "订单编号" },
                { filter: "shopNames", label: "门店名称" },
                { filter: "shopNo", label: "门店编号" },
              ],
            },
            {
              label: "到货日期",
              seniorSearch: true,
              type: "date",
              model: "",
              filter: "arrivalDate",
            },
            this.$select({ key: "listGoods", option: { seniorSearch: true, option: { multiple: true }} }),
            this.$select({ key: "listCategory", option: { seniorSearch: true, option: { multiple: true }} }),
            this.$select({ key: "freshlyBakedListRoom", option: { seniorSearch: true, option: { multiple: true }} }),
            // this.$select({ key: "freshlyBakedProduceStatus", option: { seniorSearch: true, model: "1" } }),
            { type: "button", tip: "查询", btnType: "primary", click: "search" },
            {
              type: "button",
              tip: "高级查询",
              btnType: "primary",
              click: "seniorSearch",
            },
            { type: "button", tip: "重置", click: "reset" },
          ],
          buttons: [
            {
              click: "freshlyProduceFinish",
              label: "生产完成",
              type: "primary",
              disabled: false,
            },
            
            // {
            //   click: "freshlyMoveToShop",
            //   label: "调至门店",
            //   type: "primary",
            //   disabled: false,
            // },
            {
              click: "refresh",
              right: true,
              label: "刷新",
              icon: "el-icon-refresh",
              type: "",
            },
          ],
          columns: [
            {
              prop: "billNo",
              label: "订单编号",
              minWidth: 180,
            },
            {
              prop: "billDate",
              label: "订单日期",
              minWidth: 120,
            },
            {
              prop: "shopNo",
              label: "门店编号",
              minWidth: 120,
            },
            {
              prop: "shopName",
              label: "门店名称",
              minWidth: 120,
            },
            {
              prop: "freshlyBakedProduceStatus",
              label: "完成状态",
              formatter: (v, row) => row.freshlyBakedProduceStatus == 1? '待生产' : row.freshlyBakedProduceStatus == 2? '生产完成' : '已调出',
              minWidth: 120,
            },
            {
              prop: "freshlyBakedFinishTime",
              label: "完成时间",
              minWidth: 120,
            },
            {
              prop: "freshlyBakedOutTime",
              label: "调出时间",
              minWidth: 120,
            },
            {
              prop: "freshlyBakedRoomWorkerName",
              label: "现烤师",
              minWidth: 100,
            },
            {
              prop: "roomName",
              label: "现烤间",
              minWidth: 100,
            },
            {
              prop: "arrivalDate",
              label: "到货日期",
              minWidth: 100,
            },
            {
              prop: "freshlyBakedPlanTime",
              label: "到货时间",
              minWidth: 100,
            },
            {
              prop: "categoryNo",
              label: "类别编号",
              minWidth: 140,
            },
            {
              prop: "categoryName",
              label: "类别名称",
              minWidth: 160,
            },
            {
              prop: "goodsNo",
              label: "商品编号",
              minWidth: 160,
            },
            {
              prop: "goodsName",
              label: "商品名称",
              minWidth: 160,
            },
            {
              prop: "barcode",
              label: "条码",
              minWidth: 120,
            },
            {
              prop: "goodsSpec",
              label: "规格",
              minWidth: 160,
            },
            {
              prop: "unitName",
              label: "单位",
              minWidth: 120,
            },
            {
              prop: "unitPrice",
              label: "单价",
              minWidth: 120,
            },
            {
              prop: "unitQty",
              label: "数量",
              minWidth: 120,
            },
            {
              prop: "unitMoney",
              label: "金额",
              minWidth: 120,
            },
          ],
          list: [],
        },
      };
    },
    // beforeRouteEnter(to, from, next) {
    // //   next(async (vm) => {
    // //     //从首页待办业务进来
    // //     if (to.query.billStatus && from.fullPath == "/index") {
    // //       vm.options.defaultBody = { billStatus: to.query.billStatus };
    // //       vm.options.search[4].model = to.query.billStatus
    // //       vm.options.search[1].defaultValue = ''
    // //       let cloneData = cloneDeep(vm.options);
    // //       vm.options = null;
    // //       vm.options = cloneData;
    // //     }
    // //   });
    // },
    methods: {
      cancel() {
        this.dialogFormVisible = false;
      },
      workerClick(index) {
        this.workerIndex = index;
      },
      async addBrand() {
        if (!this.workerList.length) {return this.$message.error('暂无现烤师')}
        await this.$refs["ruleForm"].validate();
        let list = this.workerList[this.workerIndex];
        const selectData = this.options.check;
        console.log( selectData, ' selectData');
        const params = selectData.map((x) => ({
          billDetailId: x.billDetailId,
          freshlyBakedRoomWorkerId: list.workerId,
          roomStoreId: x.roomStoreId,
          freshlyBakedScrapQty: this.form.freshlyBakedScrapQty,
          freshlyBakedFinishQty: this.form.freshlyBakedFinishQty,
        }));
        freshlyProduceFinish(params).then(() => {
            this.$nextTick(() => {
                this.options.check = [];
            });
            this.$modal.msgSuccess(`操作成功`);
            this.dialogFormVisible = false;
            setTimeout(() => {
                this.$refs.tablePage.getList();
            }, 500);
        });
        console.log('确定');
      },
      async handleEvent(type, row) {
        console.log(type, row);
        console.log('check', this.options.check);
        const selectData = this.options.check;
        
        switch (type) {
          case "rideo":
            {
              
              console.log(this.options.rideoStatus, 'this.options.rideoStatus');
              // this.options.rideoStatus =
              if (this.options.rideoStatus == 1) {
                this.options.buttons = [
                  {
                    click: "freshlyProduceFinish",
                    label: "生产完成",
                    type: "primary",
                    disabled: false,
                  },
                  {
                    click: "refresh",
                    right: true,
                    label: "刷新",
                    icon: "el-icon-refresh",
                    type: "",
                  },
                ]
                this.options.defaultBody = {
                  freshlyBakedProduceStatus: '1'
                }
              } else if (this.options.rideoStatus == 2) {
                this.options.buttons = [
                  {
                    click: "freshlyMoveToShop",
                    label: "调至门店",
                    type: "primary",
                    disabled: false,
                  },
                  {
                    click: "refresh",
                    right: true,
                    label: "刷新",
                    icon: "el-icon-refresh",
                    type: "",
                  },
                ]
                this.options.defaultBody = {
                  freshlyBakedProduceStatus: '2'
                }
              } else {
                this.options.buttons = [
                  {
                    click: "refresh",
                    right: true,
                    label: "刷新",
                    icon: "el-icon-refresh",
                    type: "",
                  },
                ]
                this.options.defaultBody = {
                  freshlyBakedProduceStatus: '3'
                }
              }
              this.$refs.tablePage.getList();
            }
            break;
          case "freshlyMoveToShop":
            {

              // setTimeout(() => {
              //       this.$refs.tablePage.getList();
              //     }, 500);
              //     // return
              const arrData = selectData.map((x) => ({
                  produceBillDetailId: x.produceBillDetailId,
                  orderStoreId: x.orderStoreId,
                  roomStoreId: x.roomStoreId,
              }));
              const that = this
              let list = selectData.filter((item) => item.freshlyBakedProduceStatus == '2')
              if (!list.length) {
                return that.$message.error('请选择生产完成单据')
              }
              freshlyMoveToShop(arrData).then(() => {
                  that.$nextTick(() => {
                    that.options.check = [];
                  });
                  that.$modal.msgSuccess(`操作成功`);
                  setTimeout(() => {
                    that.$refs.tablePage.getList();
                  }, 500);
              });
            }
            break;
          case "freshlyProduceFinish":
            {

              let list = selectData.filter((item) => item.freshlyBakedProduceStatus == '1')
              const freshlyBakedRoomIds =  list.map((i) => i.roomId)
              if (!list.length) {
                return this.$message.error('请选择待生产单据')
              }
              listBrand({
                pageNum: 1,
                pageSize: 100,
                freshlyBakedRoomIds: freshlyBakedRoomIds,
                delFlag: 0
              }).then((res) => {
                this.workerList = res.rows;
                this.workerIndex = 0;
                this.form.freshlyBakedScrapQty = 0;
                this.form.freshlyBakedFinishQty = list[0].unitQty;
                this.dialogFormVisible = true;
              })
              // this.dialogOptions = {
              //   curType: 'roomId',
              //   title: '选择现烤师',
              //   width: 1250,
              //   show: true,
              //   type: 'TreeAndTable',
              //   // formData: this.$dialog({ key: 'freshlyListOrder' })
              //   formData: this.$dialog({ key: 'freshlyListOrder', option:{ queryParams: { delFlag: 0 }, isCheckList: true}})
              // }
              // this.dialogOptions.formData.table.mutiSelect = false
              // this.dialogOptions.formData.table.radioSelect = true
            }
            break;
          case "dialogChange":
            {
              let list = this.dialogOptions.formData.table.check
              const params = selectData.map((x) => ({
                billDetailId: x.billDetailId,
                freshlyBakedRoomWorkerId: list[0].workerId,
                // orderStoreId: x.orderStoreId,
                roomStoreId: x.roomStoreId,
              }));
              freshlyProduceFinish(params).then(() => {
                  this.$nextTick(() => {
                      this.options.check = [];
                  });
                  this.$modal.msgSuccess(`操作成功`);
                  setTimeout(() => {
                      this.$refs.tablePage.getList();
                  }, 500);
              });
            }
            break;
          default:
            break;
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
    .workerBox {
      width: 240px;
      overflow: hidden;
      height: 200px;
      overflow: scroll;
      .li {
        width: 66px;
        height: 30px;
        border-radius: 10px;
        float: left;
        margin-bottom: 10px;
        margin-right: 10px;
        background: #0370ff96;
        line-height: 30px;
        font-size: 12px;
        text-align: center;
        color: #fff;
      }
      .activeLi {
        color: #fff;
        background: #0370FF;
        
      }
    }
    ::-webkit-scrollbar {
      width: 4px; /* 设置滚动条的宽度 */
    }
    
    /* 定制滚动条轨道 */
    ::-webkit-scrollbar-track {
      background: #f1f1f1; /* 设置轨道的背景颜色 */
    }
    
    /* 定制滚动条的滑块（thumb） */
    ::-webkit-scrollbar-thumb {
      background: #888; /* 设置滑块的背景颜色 */
    }
    
    /* 当滑块悬停或活动时的样式 */
    ::-webkit-scrollbar-thumb:hover {
      background: #ccc; /* 设置滑块在悬停状态下的背景颜色 */
    }
  </style>
  